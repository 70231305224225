@import "./board.scss";
@import "./languageswitcher.scss";
@import "./messaging.scss";
@import "./tutorial.scss";

.ant-btn-primary {
  background-color: #294895 !important;
  border: 1px solid #294895;
  &:hover {
    color: #294895 !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.ant-btn.ant-btn-primary:not(.ant-btn-dangerous) {
  background-color: #294895;
}
button.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}

.my-vacancies-grid > .ant-ribbon-wrapper {
  width: min-content;
  min-width: 60%;
  max-width: 100%;
}

.split-view-view {
  overflow: scroll !important;
  min-height: 80vh;
}
.split-view-view-visible,
.split-view {
  min-height: 80vh;
}
.ReactModal__Overlay {
  z-index: 999;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white !important;
}

.ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
  margin: 0% !important;
  width: auto !important;
}

@media (min-width: 1024px) {
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.darkModeCorrector > input {
  background: transparent;
}
body {
  scroll-behavior: smooth !important;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.faq-answer.open {
  max-height: 1000px; // Adjust as necessary to fit content
}

@keyframes bounce-once {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(
      -2%
    ); /* Adjust the percentage to control the height */
  }
}

.animate-bounce-once {
  animation: bounce-once 1s ease-in-out 1; /* Runs only once */
}
/* SpacingControl.css */
.spacing-control-container {
  padding: 16px;
  border-radius: 8px;
  width: 300px;
  margin: 0 auto;
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.spacing-control-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: center;
}

.spacing-box {
  position: relative;
  padding: 8px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  gap: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.padding-box {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: #e9ecef;
}

.spacing-label {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 8px;
  font-size: 12px;
  z-index: 1;
  background-color: #f7f7f7;
}

.margin-label {
  top: -16px;
}

.padding-label {
  top: -16px;
}

.spacing-input {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  padding: 4px;
  background-color: #f1f1f1;
}

.spacing-input input {
  width: 100%;
  background: none;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #333;
  padding: 0;
  -moz-appearance: textfield; /* Hide native arrows in Firefox */
}

.spacing-input input::-webkit-outer-spin-button,
.spacing-input input::-webkit-inner-spin-button {
  opacity: 0;
  cursor: ns-resize; /* Change cursor to indicate draggable area */
}

.spacing-input.top-input {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
}

.spacing-input.left-input {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.spacing-input.bottom-input {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
}

.spacing-input.right-input {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
}

.spacing-input.padding.top-input {
  grid-column: 1 / span 4;
  grid-row: 1 / span 1;
}

.spacing-input.padding.bottom-input {
  grid-column: 1 / span 4;
  grid-row: 3 / span 1;
}

.spacing-input.left-input.padding {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
}

.spacing-input.right-input.padding {
  grid-column: 3 / span 2;
  grid-row: 2 / span 1;
}

.padding-container {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2px;
  padding: 8px;
}
