:root {
  --indigo-50: #d3e0fb;
  --indigo-100: #bacef9;
  --indigo-200: #89aaf4;
  --indigo-300: #5787f0;
  --indigo-400: #2563eb;
  --indigo-500: #2563eb;
  --indigo-600: #1554e0;
  --indigo-700: #124bc7;
  --indigo-800: #1042ae;
  --indigo-900: #0e3895;
  --indigo-950: #0d3489;
}

:root {
  --blue-50: #edfaff;
  --blue-100: #d6f2ff;
  --blue-200: #b5eaff;
  --blue-300: #83dfff;
  --blue-400: #48cbff;
  --blue-500: #1eadff;
  --blue-600: #068fff;
  --blue-700: #007bff;
  --blue-800: #085ec5;
  --blue-900: #0d519b;
  --blue-950: #0e315d;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-width: 250px;
}

.text-red {
  color: #ff2400;
}

/* Custom scrollbar styles */
*::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
  height: 7px; /* Width of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll thumb */
  border-radius: 10px; /* Rounded corners on the scroll thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scroll thumb on hover */
}

/* For IE, Edge, and Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 100vh 100vw rgba(0, 0, 0, 0.15) inset;
  }
  50% {
    box-shadow: 0 0 100vh 100vw rgba(0, 0, 0, 0.35) inset;
  }
  100% {
    box-shadow: 0 0 100vh 100vw rgba(0, 0, 0, 0.15) inset;
  }
}

.splash {
  animation: pulse 2s infinite ease-in-out;
}

.ant-modal-content {
  min-width: 300px;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(145, 92, 182, 0.75);
  }
  50% {
    box-shadow: 0 0 20px rgba(145, 92, 182, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(145, 92, 182, 0.75);
  }
}

.glowing-border {
  animation: glow 2s infinite ease-in-out;
}

.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-7 {
  margin-right: 1.75rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-9 {
  margin-right: 2.25rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mr-11 {
  margin-right: 2.75rem;
}
.mr-12 {
  margin-right: 3rem;
}

.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-7 {
  margin-left: 1.75rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-9 {
  margin-left: 2.25rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.ml-11 {
  margin-left: 2.75rem;
}
.ml-12 {
  margin-left: 3rem;
}

.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-9 {
  margin-top: 2.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-11 {
  margin-top: 2.75rem;
}
.mt-12 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-9 {
  margin-bottom: 2.25rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-11 {
  margin-bottom: 2.75rem;
}
.mb-12 {
  margin-bottom: 3rem;
}

.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-7 {
  padding-right: 1.75rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-9 {
  padding-right: 2.25rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pr-11 {
  padding-right: 2.75rem;
}
.pr-12 {
  padding-right: 3rem;
}

.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-7 {
  padding-left: 1.75rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-9 {
  padding-left: 2.25rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pl-11 {
  padding-left: 2.75rem;
}
.pl-12 {
  padding-left: 3rem;
}

.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-7 {
  padding-top: 1.75rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pt-9 {
  padding-top: 2.25rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-11 {
  padding-top: 2.75rem;
}
.pt-12 {
  padding-top: 3rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-7 {
  padding-bottom: 1.75rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-9 {
  padding-bottom: 2.25rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-11 {
  padding-bottom: 2.75rem;
}
.pb-12 {
  padding-bottom: 3rem;
}

.render-quil-html > h1 {
  font-size: 20px;
}
.render-quil-html > h2 {
  font-size: 18px;
}
.render-quil-html > h3 {
  font-size: 16px;
}
.render-quil-html > h4 {
  font-size: 14px;
}
.render-quil-html > h5 {
  font-size: 12px;
}

.ql-editor > h1 {
  margin-top: 1rem !important;
}
.ql-editor > h2 {
  margin-top: 0.75rem !important;
}
.ql-editor > h3 {
  margin-top: 0.5rem;
}
.ql-editor > h4 {
  margin-top: 0.25rem;
}

.ql-editor > p > img {
  width: 300px;
  cursor: pointer;
  margin: auto;
}
