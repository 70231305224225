.languageswitch-wrapper {
  background: rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 20px;

  .changeLanguage .dropbtn {
    background-color: transparent;
    /* padding-left: 10px; */
    border: none;
    cursor: pointer;
    /* margin-right: 20px; */
    font-size: 12px;
    color: #162542;
    text-transform: uppercase;
    font-weight: 500;
  }
  .changeLanguage .dropdown img {
    display: initial;
  }

  .changeLanguage .dropdown {
    position: relative;
    display: inline-block;
    background-color: transparent;
    margin-top: -2px;
    display: inherit !important;
  }

  .changeLanguage .dropdown-content {
    position: absolute;
    background-color: #fff;
    min-width: 45px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 25px;
    right: 0px;
  }
  .changeLanguage .dropdown-content button,
  .changeLanguage .dropdown-content div {
    padding: 8px 2px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    color: #162542;
    text-transform: uppercase;
    font-weight: 500;
    width: 100%;
    text-align: right;
  }
  .changeLanguage .dropdown-content button:hover,
  .changeLanguage .dropdown-content div:hover {
    background-color: #f2f2f2;
    width: 100%;
  }
  .changeLanguage .dropdown-content button:hover {
    background-color: #f2f2f2;
    width: 100%;
  }

  .changeLanguage .dropdown-content a img {
    margin-right: 7px;
  }
  .changeLanguage .dropdown-content a:hover {
    background-color: #f2f2f2;
  }

  .changeLanguage .dropdown:hover .dropdown-content {
    display: block;
  }

  .theme-dark .changeLanguage .dropdown-content {
    background-color: #1a1c23;
    color: #e5e7eb;
  }
  .theme-dark .changeLanguage .dropdown-content a {
    color: #e5e7eb;
  }
  .theme-dark .changeLanguage .dropdown-content a:hover {
    background-color: #121317;
  }

  .theme-dark .changeLanguage .dropbtn {
    color: #e5e7eb;
  }
}
